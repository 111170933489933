import { isBrowser } from '@dx-ui/utilities-is-browser';
import type { EnvKeys, PublicEnv } from './types';

/**
 * Isomorphic function that returns all environment variables.
 * @note This is only available on the client when the `EnvScript` component is used.
 */
export function allEnv() {
  return isBrowser ? window.__ENV || {} : (process.env as unknown as PublicEnv);
}

/**
 * Isomorphic getter that returns a specific environment variable.
 * @note This is only available on the client when the `EnvScript` component is used.
 * @param key
 */
export function env(key: EnvKeys, defaultValue?: string) {
  return allEnv()[key] || defaultValue || '';
}
